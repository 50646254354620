import { NoCodeCellDataframe } from "../sql/dataSourceTableConfig.js";
import { uuid } from "../uuid.js";

import {
  BaseChartSeries,
  ChartAreaSeries,
  ChartAxisStyle,
  ChartBarSeries,
  ChartHistogramSeries,
  ChartLayer,
  ChartLineSeries,
  ChartPieSeries,
  ChartScatterSeries,
  LayeredChartSpec,
} from "./types";

export function newDefaultChart(
  dataFrame?: NoCodeCellDataframe,
): LayeredChartSpec {
  return {
    type: "layered",
    layers: [newDefaultLayer(dataFrame)],
    settings: {
      tooltip: true,
      timezone: undefined,
      legend: {
        position: "right",
      },
      selectionEnabled: false,
    },
  };
}

export function newSuggestibleChart(
  dataFrame?: NoCodeCellDataframe,
): LayeredChartSpec {
  const chart = newDefaultChart(dataFrame);
  if (chart.layers[0]) {
    chart.layers[0].series = [];
  }
  return chart;
}

export function newDefaultLayer(dataFrame?: NoCodeCellDataframe): ChartLayer {
  return {
    id: uuid(),
    dataFrame,
    xAxis: {
      type: "string",
      style: newDefaultAxisStyle(),
    },
    series: [newDefaultBarSeries()],
  };
}

export function newDefaultBarSeries(): ChartBarSeries {
  const baseSeries = newDefaultBaseSeries();
  return {
    ...newDefaultBaseSeries(),
    type: "bar",
    barWidth: 1,
    layout: "stacked",
    orientation: "vertical",
    axis: {
      ...baseSeries.axis,
    },
  };
}

export function newDefaultLineSeries(): ChartLineSeries {
  return {
    ...newDefaultBaseSeries(),
    type: "line",
    point: false,
    stroke: "solid",
  };
}

export function newDefaultScatterSeries(): ChartScatterSeries {
  return {
    ...newDefaultBaseSeries(),
    type: "scatter",
    filled: true,
  };
}

export function newDefaultAreaSeries(): ChartAreaSeries {
  return {
    ...newDefaultBaseSeries(),
    type: "area",
    point: false,
    line: true,
    normalize: false,
  };
}

export function newDefaultHistogramSeries(): ChartHistogramSeries {
  const baseSeries = newDefaultBaseSeries();
  return {
    ...baseSeries,
    type: "histogram",
    bin: {
      type: "count",
      value: 10,
    },
    format: "count",
  };
}

export function newDefaultPieSeries(): ChartPieSeries {
  const baseSeries = newDefaultBaseSeries();
  return {
    ...baseSeries,
    type: "pie",
    radius: 0,
    axis: {
      ...baseSeries.axis,
      type: "string",
    },
    showAsPct: false,
  };
}

export function newDefaultAxisStyle(): ChartAxisStyle {
  return {
    grid: {
      style: "solid",
    },
    ticks: {},
    labels: {},
  };
}

function newDefaultBaseSeries(): BaseChartSeries {
  return {
    id: uuid(),
    type: "bar",
    dataFrameColumns: [],
    axis: {
      type: "number",
      style: newDefaultAxisStyle(),
    },
    colorOrder: "ascending",
    color: {
      type: "static",
    },
    opacity: {
      type: "static",
      value: 1,
    },
    tooltip: {
      type: "auto",
    },
  };
}
