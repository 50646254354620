import { groupBy, zip } from "lodash";

import { asciiCompare, getIndicesBetween } from "../fractionalIndexing.js";
import { BlockCellId, ComponentImportCellId } from "../idTypeBrands.js";

import { BaseGraphCellV3 } from "./projectGraphV3.js";

type CellWithInterpolatedOrder<C extends BaseGraphCellV3> = [C, string];

export function getFlattenedSortedCells<C extends BaseGraphCellV3>(
  cells: C[],
): CellWithInterpolatedOrder<C>[] {
  return interpolateBlockOrders(replaceComponentImportCells(cells));
}

function replaceComponentImportCells<C extends BaseGraphCellV3>(
  cells: C[],
): CellWithInterpolatedOrder<C>[] {
  const cellsImportedByComponent: Record<ComponentImportCellId, C[]> = groupBy(
    cells.filter((cell) => cell.parentComponentImportCellId != null),
    "parentComponentImportCellId",
  );
  const sortedNotInComponent = cells
    .filter((cell) => cell.parentComponentImportCellId == null)
    .sort((a, b) => asciiCompare(a.order, b.order));
  return sortedNotInComponent.flatMap((cell, idx) => {
    if (cell.componentImportCellId != null) {
      const sortedChildren = (
        cellsImportedByComponent[cell.componentImportCellId] ?? []
      ).sort((a, b) => asciiCompare(a.order, b.order));
      const interpolatedOrders = getIndicesBetween({
        start: cell.order,
        end: sortedNotInComponent[idx + 1]?.order,
        count: sortedChildren.length,
      });
      return zip(
        sortedChildren,
        interpolatedOrders,
      ) as CellWithInterpolatedOrder<C>[]; // `zip` types don't assume same length
    } else {
      return [[cell, cell.order]];
    }
  });
}

function interpolateBlockOrders<C extends BaseGraphCellV3>(
  cells: CellWithInterpolatedOrder<C>[],
): CellWithInterpolatedOrder<C>[] {
  const blockChildren: Record<BlockCellId, C[]> = groupBy(
    cells
      .map(([cell]) => cell)
      .filter((cell) => cell.parentBlockCellId != null),
    "parentBlockCellId",
  );
  const withoutBlockChildren = cells.filter(
    ([cell]) => cell.parentBlockCellId == null,
  );
  return withoutBlockChildren.flatMap(([cell, order], idx) => {
    if (cell.blockCellId != null) {
      const sortedChildren = (blockChildren[cell.blockCellId] ?? []).sort(
        (a, b) => asciiCompare(a.order, b.order),
      );
      const interpolatedOrders = getIndicesBetween({
        start: order,
        end: withoutBlockChildren[idx + 1]?.[1],
        count: sortedChildren.length,
      });
      return [
        [cell, order],
        ...(zip(
          sortedChildren,
          interpolatedOrders,
        ) as CellWithInterpolatedOrder<C>[]),
      ];
    } else {
      return [[cell, order]];
    }
  });
}
