import { NumberColumnDisplayFormat } from "../display-table/columnDisplayFormatTypes.js";

export const CHART_AGGREGATE_MAX_VALUE_COUNT = 100;
export const CHART_PIVOT_TOOLTIP_MAX_SERIES = 20;
export const CHART_DEFAULT_RULE_COLOR_MARKER =
  "CHART_DEFAULT_RULE_COLOR_MARKER";

export const CHART_AXIS_TITLE_MAX_LEN = 80;
export const CHART_MAX_FACETS = 100; //sync with chart_cell.py

export const DEFAULT_CHART_RESULT_VARIABLE = "chart_result";

export const CHART_INTERVAL_SELECTION_NAME = "interval_intervalselection_";
export const CHART_LEGEND_SELECTION_NAME = "legend_pointselection_";
export const CHART_POINT_SELECTION_NAME = "click_pointselection_";
export const CHART_LEGEND_HOVER_NAME = "legend_pointhover_";

export const CHART_AUTO_LIGHT_DARK_COLOR_PLACEHOLDER =
  "CHART_AUTO_LIGHT_DARK_COLOR_PLACEHOLDER";

export const CHART_DEFAULT_NUMBER_FORMAT: NumberColumnDisplayFormat = {
  format: "NUMBER",
  columnType: "NUMBER",
  numDecimalDigits: -1,
  currency: "$",
  nanFormat: "",
};

export const SUM_Y_BY_X = "sum_y_by_x";
