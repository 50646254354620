import { HEX_PRIVATE_PREFIX } from "../languageUtils.js";
import {
  NoCodeCellDataframe,
  getDataframeName,
  getNoCodeCellInputReference,
} from "../sql/dataSourceTableConfig.js";
import { typedObjectEntries } from "../utils/typedObjects.js";

import {
  ChartCustomSort,
  ChartLayer,
  ChartSpec,
  LayeredChartSpec,
} from "./types";

/**
 * Returns the dataframe name for a given chart layer.
 */
export function getChartLayerDataframeName(layer: ChartLayer): string | null {
  return getDataframeName(layer.dataFrame ?? null);
}

/**
 * Returns a list of dataframe names referenced by a chart. If you just need to
 * get the raw dataframe object, @see getChartReferencedDataFrames
 */
export function getChartReferencedDataFrameNames(
  chart: ChartSpec,
): readonly string[] {
  if (chart.type !== "layered") {
    return [];
  }

  const dataFrameSet = new Set<string>();
  chart.layers.forEach((layer) => {
    const dataframeName = getChartLayerDataframeName(layer);
    if (dataframeName != null) {
      dataFrameSet.add(dataframeName);
    }

    layer.series.forEach((series) => {
      if (series.dataFrame != null) {
        dataFrameSet.add(series.dataFrame);
      }
    });
  });
  return Array.from(dataFrameSet);
}

/**
 * Returns a list of values referenced by a chart - is either the name of the
 * dataframe or the path to table (if WHNC)
 */
export function getChartReferencedInputs(chart: ChartSpec): readonly string[] {
  if (chart.type !== "layered") {
    return [];
  }

  const dataFrameSet = new Set<string>();
  chart.layers.forEach((layer) => {
    const inputName = layer.dataFrame
      ? getNoCodeCellInputReference(layer.dataFrame)
      : null;
    if (inputName != null) {
      dataFrameSet.add(inputName);
    }

    layer.series.forEach((series) => {
      if (series.dataFrame != null) {
        dataFrameSet.add(series.dataFrame);
      }
    });
  });
  return Array.from(dataFrameSet);
}

/**
 * Returns a list of dataframe objects (either string or a DataSourceTableConfig
 * object) referenced by a chart. If you just need to get the dataframe name,
 * @see getChartReferencedDataFrameNames
 */
export function getChartReferencedDataFrames(
  chart: ChartSpec,
): readonly NoCodeCellDataframe[] {
  if (chart.type !== "layered") {
    return [];
  }

  const dataFrameSet = new Set<NoCodeCellDataframe>();
  chart.layers.forEach((layer) => {
    if (layer.dataFrame != null) {
      dataFrameSet.add(layer.dataFrame);
    }
    layer.series.forEach((series) => {
      if (series.dataFrame != null) {
        dataFrameSet.add(series.dataFrame);
      }
    });
  });
  return Array.from(dataFrameSet);
}

export function replaceChartDataFrameName(
  chart: ChartSpec,
  originalDataFrameName: string,
  newName: string,
): LayeredChartSpec {
  if (chart.type !== "layered") {
    throw new Error("Only layered charts are supported");
  }

  return {
    ...chart,
    layers: chart.layers.map((layer) => {
      const series = layer.series.map((s) => {
        if (s.dataFrame === originalDataFrameName) {
          return {
            ...s,
            dataFrame: newName,
          };
        }
        return s;
      });

      if (
        layer.dataFrame != null &&
        getDataframeName(layer.dataFrame) === originalDataFrameName
      ) {
        return { ...layer, series, dataFrame: newName };
      }
      return { ...layer, series };
    }),
  };
}

export type DataFrameColumnsByDataFrame = Record<string, string[]>;

/**
 * Gets the list of dataframe columns that are used to compute the
 * order for series values, such as the categorical order for x-axis
 * or order of grouped bars/lines
 */
export function getChartSeriesValuesDataFrameColumns(
  chart: ChartSpec,
): DataFrameColumnsByDataFrame {
  const ret: DataFrameColumnsByDataFrame = {};
  if (chart.type !== "layered") {
    return ret;
  }

  const columnsByDataFrame: Record<string, Set<string>> = {};
  for (const layer of chart.layers) {
    for (const series of layer.series) {
      const dfName = series.dataFrame ?? getChartLayerDataframeName(layer);

      if (dfName == null) {
        continue;
      }

      if (columnsByDataFrame[dfName] == null) {
        columnsByDataFrame[dfName] = new Set<string>();
      }

      if (
        (ChartCustomSort.guard(layer.xAxis.sort) ||
          layer.xAxis.type === "string" ||
          // Always include the xAxis column for bar charts as we use this
          // to set bar widths for integer columns
          series.type === "bar") &&
        layer.xAxis.dataFrameColumn != null
      ) {
        columnsByDataFrame[dfName]?.add(layer.xAxis.dataFrameColumn);
      }

      if (series.colorDataFrameColumn != null) {
        columnsByDataFrame[dfName]?.add(series.colorDataFrameColumn);
      }
    }
  }

  Object.entries(columnsByDataFrame).forEach(([df, columns]) => {
    if (columns.size > 0) {
      ret[df] = Array.from(new Set(columns));
    }
  });
  return ret;
}

export function getChartDataFrameColumns(
  chart: ChartSpec,
): DataFrameColumnsByDataFrame {
  // should be kept in sync with chart_cell.py:columns_in_spec
  // per Suchan, maybe this function's result could be pushed to Python
  // and the Python version could be removed
  const result: DataFrameColumnsByDataFrame = {};
  if (chart.type !== "layered") {
    return result;
  }

  for (const layer of chart.layers) {
    for (const series of layer.series) {
      const df = series.dataFrame ?? getChartLayerDataframeName(layer);
      if (df == null) {
        continue;
      }

      const columns: string[] = result[df] ?? [];

      // implicitly this is expecting every layer's df to have the facet cols
      if (chart.facet?.facetHorizontal?.dataFrameColumn != null) {
        columns.push(chart.facet.facetHorizontal.dataFrameColumn);
      }
      if (chart.facet?.facetVertical?.dataFrameColumn != null) {
        columns.push(chart.facet.facetVertical.dataFrameColumn);
      }

      if (layer.xAxis.dataFrameColumn != null) {
        columns.push(layer.xAxis.dataFrameColumn);
      }

      columns.push(...series.dataFrameColumns);
      if (series.colorDataFrameColumn != null) {
        columns.push(series.colorDataFrameColumn);
      }
      if (
        series.color?.type === "dataframe" &&
        series.color?.dataFrameColumn != null
      ) {
        columns.push(series.color?.dataFrameColumn);
      }
      if (
        series.opacity?.type === "dataframe" &&
        series.opacity?.dataFrameColumn != null
      ) {
        columns.push(series.opacity?.dataFrameColumn);
      }
      if (series.tooltip?.type === "manual") {
        for (const tooltip of series.tooltip.fields) {
          columns.push(tooltip.dataFrameColumn);
        }
      }

      result[df] = columns;
    }
  }

  typedObjectEntries(result).forEach(([df, columns]) => {
    result[df] = Array.from(new Set(columns));
  });
  return result;
}

export function getChartResultVariable({
  outputResult,
  resultVariable,
}: {
  resultVariable: string;
  outputResult: boolean;
}): string {
  return outputResult
    ? resultVariable
    : `${HEX_PRIVATE_PREFIX}chart_${resultVariable}`;
}
