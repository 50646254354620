import type { TimezoneName } from "./dateTypes";

export const MAX_KERNEL_TIMEOUT_MILLIS = 7 * 24 * 60 * 60 * 1000; // 1 week

export const MAX_FILE_COUNT = 100;

export const TRASHED_HEX_LIFETIME_IN_DAYS = 7;

export const DATA_FRAME_SQL_CONNECTION_ID = "dataFrameCell";
export type DATA_FRAME_SQL_CONNECTION_ID = typeof DATA_FRAME_SQL_CONNECTION_ID;

export const MULTISELECT_SELECT_ALL_OPTION =
  "__hex_multiselect_select_all_option__";

export const DEFAULT_FONT_FAMILY = `"IBM Plex Sans", system-ui, -apple-system, BlinkMacSystemFont, sans-serif`;

export const UTC = "UTC" as TimezoneName;
export const DEFAULT_TIMEZONE = UTC;

export const HEX_SECURE_VALUE_DUMMY_VALUE = "$$hexdummyvalue$$";

// used for S3 integration where we have field validation on 40 char length
export const HEX_SECURE_VALUE_DUMMY_VALUE_40_CHAR =
  "$$$$$$$$$$$$$$$hexdummyvalue$$$$$$$$$$$$";

export const EXPLORE_LIFETIME_IN_DAYS = 14;
